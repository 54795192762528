import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`1. ANSVARSFRASKRIVELSE`}</h2>
    <br />
    <p>{`Du må alltid gjøre egne vurderinger basert på observasjoner i terrenget.`}</p>
    <br />
    <p>{`Bruk informasjonen du får i appen som et hjelpemiddel og følg de rådene som gis i
appen og fjellvettreglene.`}</p>
    <br />
    <p>{`Vurder alltid risikoen i utsatte områder ved å velge hvor, når, og hvordan du ferdes.`}</p>
    <br />
    <p>{`Informasjonen i appen er ikke alltid oppdatert og det forekommer feil og mangler.`}</p>
    <br />
    <p>{`Informasjonen du får er ofte basert på regional informasjon og beskriver ikke forholdene
akkurat på det stedet du befinner deg.`}</p>
    <br />
    <p>{`Informasjon om vær- og føre er basert på tilgjengelige observasjoner og værprognoser.
Værforholdene endres fort og kan derfor avvike fra det som er varslet.`}</p>
    <br />
    <p>{`Raske endringer i værforholdene kan bety at rutebeskrivelser ikke alltid stemmer med
forholdene den dagen du ferdes i fjellet.`}</p>
    <br />
    <p>{`Husk at terrengfeller og andre lokale forhold sjelden kan gjengis presist, og at du
alltid må gjøre en konkret vurdering av lokale forhold.`}</p>
    <br />
    <p>{`Følg alltid fjellvettreglene og konsulter andre som nylig har vært i området du ferdes i.`}</p>
    <br />
    <p>{`Du bruker alltid informasjonen i appen på eget ansvar. Ved å ta appen i bruk aksepterer
du begrensningene og ansvarsbegrensningen.`}</p>
    <br />
    <p>{`Tilbyder av appen og de enkelte bidragsyterne som leverer informasjonen i appen er ikke
ansvarlig for følgene av å bruke informasjonen og fraskriver seg ethvert ansvar for
direkte og indirekte tap.`}</p>
    <br />
    <h2>{`2. PRIVACY`}</h2>
    <br />
    <h2>{`5. REGISTRATION OF USAGE DATA`}</h2>
    <br />
    <h2>{`6. FINAL PROVISIONS`}</h2>
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      